import { useRouter } from 'next/router';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { getLanguageId } from '@helpers/language';
import { Card } from '@components';

const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

const RelatedPost = ({
  data,
  postTextBodyEnabled,
  subjectEnabled,
  shortDescriptionEnabled,
  lmageAltEnabled,
  linkOutEnabled,
  imageEnabled,
}) => {
  const { Id, Path, PostText } = data;
  const router = useRouter();
  const { locale } = router;

  const post = PostText.find(({ LanguageId }) => parseInt(LanguageId) === getLanguageId(locale));

  return data ? (
    <ConditionalWrapper
      condition={linkOutEnabled}
      wrapper={(children) => (
        <Link href={Path ? `/${Path}` : `/${post?.UniqueTitle}`} prefetch={false}>
          <a>
            {children}
          </a>
        </Link>
      )}
    >
      <Card>
        {imageEnabled && (
          <Card.Media>
            <img
              src={`/api/static/media/byfile/posts/${Id}/post/1_N_N_N_I_640x360.jpg`}
              alt={lmageAltEnabled && post?.ImageAccessibilityInfo ? post?.ImageAccessibilityInfo : null}
            />
          </Card.Media>
        )}

        <Card.Body>
          {subjectEnabled && post?.Subject && (
            <Card.Title>{post?.Subject}</Card.Title>
          )}
          {shortDescriptionEnabled && post?.ShortDescription && (
            <Card.SubTitle>{post?.ShortDescription}</Card.SubTitle>
          )}
          {postTextBodyEnabled && post?.PostTextBody && (
            <Card.Text dangerouslySetInnerHTML={{ __html: post?.PostTextBody }} />
          )}

          {post?.ButtonText && (
            <Card.More>
              {post?.ButtonText}
              <FontAwesomeIcon icon={faChevronRight} />
            </Card.More>
          )}
        </Card.Body>
      </Card>
    </ConditionalWrapper>
  ) : null;
};

export default RelatedPost;
