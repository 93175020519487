import { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import PageContext from '@contexts/PageContext';
import { Container } from '@components';
import { checkEnabled, checkValue } from '@components/OptionalFeature';
import RelatedPost from './RelatedPost';
import styles from './RelatedPosts.module.scss';

const RelatedPosts = ({ data, pageId, componentIsAvailable, showTitle, componentTitle }) => {
  const pageData = useContext(PageContext);
  const router = useRouter();
  const [individualPostPage, setIndividualPostPage] = useState(false);
  const { locale } = router;

  useEffect(() => {
    setIndividualPostPage(pageId === 27 ? true : false);
  }, []);

  // section title on the page
  const sectionTitle = checkValue(data?.DisplayOptions, 'ComponentHeaderTitleText', locale);

  // check Enabled
  const postTextBodyEnabled = checkEnabled(data?.DisplayOptions, 'PostTextBody');
  const subjectEnabled = checkEnabled(data?.DisplayOptions, 'Subject');
  const shortDescriptionEnabled = checkEnabled(data?.DisplayOptions, 'ShortDescription');
  const lmageAltEnabled = checkEnabled(data?.DisplayOptions, 'ImageAccessibilityInfo');
  const linkOutEnabled = checkEnabled(data?.DisplayOptions, 'LinkOut');
  const imageEnabled = checkEnabled(data?.DisplayOptions, 'BackgroundHero');
  const categoryFilter = checkEnabled(data?.DisplayOptions, 'Category');

  // Promotion Value key
  const categoryFilterValue = data?.DisplayOptions.filter((options) => {
    return options.DisplayOptionKey === 'Category';
  })[0]?.OptionInformation;

  var relatedPosts = pageData.RelatedPosts;

  if(categoryFilter && categoryFilterValue != "") {
    relatedPosts = relatedPosts.filter(element =>
      element.p && element.p.PostTags && element.p.PostTags.some(tag =>
        tag.PostCategoriesTag && tag.PostCategoriesTag.Value === categoryFilterValue
      )
    );
  }

  return (
    componentIsAvailable && relatedPosts.length > 0 && (
      <section className={individualPostPage ? styles.Right : styles.Section}>
        <Container className={styles.Container}>
          {showTitle && <h2 className={styles.Title}>{sectionTitle}</h2>}
          {relatedPosts.length > 0 && (
            <div className={styles.List}>
              {relatedPosts.map(({ p: post }) => (
                <RelatedPost
                  key={post.Id}
                  data={post}
                  postTextBodyEnabled={postTextBodyEnabled}
                  subjectEnabled={subjectEnabled}
                  shortDescriptionEnabled={shortDescriptionEnabled}
                  lmageAltEnabled={lmageAltEnabled}
                  linkOutEnabled={linkOutEnabled}
                  imageEnabled={imageEnabled}
                />
              ))}
            </div>
          )}
        </Container>
      </section>
    )
  );
};

export default RelatedPosts;
